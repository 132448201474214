<template>
  <div class="app_con">
    <div class="analysis-wrap p-20" v-if="isAnalysis">
      <div class="returnList">
        <el-button type="primary" @click="isAnalysis = false"
          >返回列表</el-button
        >
      </div>
      <div class="echart">
        <echart-line :chartData="chartData"></echart-line>
      </div>
    </div>
    <app-list
      v-else
      ref="applist"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :labelWidth="labelWidth"
      :buttonShow="false"
    >
      <div slot="toolSub">
        <el-button type="primary" icon="el-icon-upload2" @click="exportExcel"
          >导出</el-button
        >
        <el-button type="success" icon="el-icon-search" @click="showAnalysis"
          >分析</el-button
        >
      </div>
      <div class="search" slot="tool">
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">开始时间</div>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="searchQuery.startDate"
            type="date"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">结束时间</div>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="searchQuery.endDate"
            type="date"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";
import echartLine from "./components/line.vue";
export default {
  components: {
    echartLine,
  },
  data() {
    return {
      remote: api.common,
      apiName: "/statistics/commodityTypeList",
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [4, 4, 4, 4],
        thirdSoltSpan: [20, 20, 20, 20],
      },
      searchVal: "",
      jobList: [],
      searchQuery: { startDate: "", endDate: "" },
      labelWidth: "120px",
      props: [
        {
          label: "类别名称",
          prop: "typeName",
          align: "center",
        },
        {
          label: "成交笔数",
          prop: "clinchNumber",
          align: "center",
        },

        {
          label: "商品重量",
          prop: "commodityWeight",
          align: "center",
        },
        {
          label: "交易金额",
          prop: "transactionAmount",
          align: "center",
        },
      ],
      formProps: [],
      isAnalysis: false,
      chartData: {
        xAxis: ["肉类", "土鸡", "土鸭", "排骨", "花蟹"],
        series: [
          {
            title: "交易笔数",
            value: [12, 32, 10, 14, 19, 23, 21],
          },
          {
            title: "商品重量",
            value: [12.0, 13.2, 10.1, 13.4, 9.0, 23.0, 21.0],
          },
          {
            title: "交易金额",
            value: [120, 132, 101, 134, 90, 230, 210],
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/statistics/commodityTypeListExportExcel",
        ...searchQuery,
      });
    },
    showAnalysis() {
      this.isAnalysis = true;
      let data = this.$refs["applist"].data;
      this.chartData.xAxis = data.map((x) => {
        return x.typeName;
      });
      this.chartData.series[0].value = data.map((x) => {
        return x.clinchNumber;
      });
      this.chartData.series[1].value = data.map((x) => {
        return x.commodityWeight;
      });
      this.chartData.series[2].value = data.map((x) => {
        return x.transactionAmount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-date-editor {
  height: 40px;
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
